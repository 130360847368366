.rdp {
  --rdp-cell-size: 35px;
  --rdp-accent-color: #4ac398;
  --rdp-background-color: #e2f5ef;
  --rdp-range-color: ;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.5);
}

.rdp-head {
  font-size: 12px;
  font-weight: 400;
  color: #8aa1b0;
}

.rdp-caption_label {
  font-size: 16px;
  font-weight: 700;
  color: #212b36;
}

.rdp-cell {
  border: 1px solid #c4d9e5;
}

.rdp-day,
.rdp-day-selected,
.rdp-day_range_end,
.rdp-day_range_start,
.rdp-day_range_end.rdp-day_range_start {
  border-radius: 0;
}

.rdp-day {
  font-size: 14;
  font-weight: 300;
}

.rdp-day_range_middle {
  background-color: var(--rdp-background-color);
  color: inherit;
}
