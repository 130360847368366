/*
  ❗ This file contains styles that apply globally to the whole application.
  ❗ Put some CSS in this file only in case of urgent need.
  ❗ Whenever possible, create local styles for your React components (CSS modules, CSS-in-JS).
  ❗ The only excuse to put CSS in this file is when you want to style a third-party library that doesn't allow customization using its API.
  ❗ If you want to apply a global style only when a certain component is mounted, use the `@global` directive of the JSS library (https://material-ui.com/customization/globals/#global-css).
*/


/*
  Removes autofill styles in all inputs (for example, login and password).
  Copy-pasted from stack overflow.
  https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
  Tested in Chrome, Safari and Firefox
*/

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}


/* clears the ‘X’ from Internet Explorer */

input[type=search]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type=search]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}


/* clears the ‘X’ from Chrome */

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

.progress-bar-root {
    position: fixed;
    bottom: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 24px;
    padding-left: 8px;
}

@font-face {
    font-family: 'Satoshi-Variable';
    src: local('Satoshi-Variable'), url('../fonts/Satoshi/Fonts/Variable/Satoshi-Variable.ttf') format('truetype');
         font-weight: 300 900;
         font-display: swap;
         font-style: normal;
  }

