.timeline-editor-wrapper {
  margin-left: -32px !important;
  margin-right: -32px !important;
  position: fixed;
  bottom: 0;
  z-index: 100;
  height: 100px !important;
  width: 100vw !important;
}

.timeline-editor-wrapper .timeline-editor {
  width: 100%;
  height: 97px !important;
  font-family: 'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background-color: transparent;
}

.timeline-editor .timeline-editor-time-area {
  border-top: 1px solid rgba(143, 160, 250, 1);
  background-color: white;
  border-bottom: 1px solid rgba(206, 215, 222, 1);
  overflow: hidden;
}

.timeline-editor .timeline-editor-edit-area {
  margin-top: 1px;
  background-color: rgba(243, 247, 249, 1);
  border-bottom: 1px solid rgba(206, 215, 222, 1);
}

.timeline-editor-wrapper .timeline-editor .timeline-editor-action {
  height: 29px !important;
  top: 100%;
  transform: translateY(-50%);
  background-color: rgba(233, 236, 254, 1);
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 4px;
}

.timeline-editor .timeline-editor-action-text {
  font-size: 12px;
  line-height: 16px;
  height: 100%;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  cursor: move;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 2px;
}

.timeline-editor .timeline-editor-time-unit {
  border-right: 1px solid rgba(206, 215, 222, 1);
  border-bottom: 1px solid rgba(206, 215, 222, 1);
}

.timeline-editor .timeline-editor-time-unit-scale {
  color:rgba(206, 215, 222, 1);
}

.timeline-editor .timeline-editor-edit-row {
  background-image: linear-gradient(rgba(243, 247, 249, 1), rgba(243, 247, 249, 1)), linear-gradient(90deg, rgba(255, 255, 255, 0.08) 1px, transparent 0);
}

.timeline-editor .ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.timeline-editor .timeline-editor-time-area-interact, .timeline-editor .timeline-editor-action-effect-punctuation {
  display: none;
}

.timeline-editor .timeline-editor-cursor {
  cursor: default;
  pointer-events: none;
  position: absolute;
  top: 21px;
  height: calc(100% - 21px);
  box-sizing: border-box;
  border-left: 1px solid #F2994A;
  border-right: 1px solid #F2994A;
  transform: translateX(-25%) scaleX(0.5);
}
.timeline-editor .timeline-editor-cursor-top {
  cursor: default;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 50%;
  height: 10px;
  transform: translate(-50%, 0) scaleX(2.5);
  margin: auto;
}

.timeline-editor .timeline-editor-cursor-top * {
  fill: #F2994A;
}

.timeline-editor .timeline-editor-cursor-area {
  width: 16px;
  height: 100%;
  cursor: default;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
