.video-js {
  font-family: 'Satoshi-Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  border: 1px solid #f3f7f9 !important;
  filter: drop-shadow(0px 4px 8px rgba(8, 15, 52, 0.06));
  border-radius: 8px !important;
}

video.vjs-tech {
  border-radius: inherit;
}

.video-js .vjs-control-bar {
  display: block;
  background: rgba(74, 74, 74, 0.55);
  border-top: 1px solid #f3f7f9;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 4px 8px rgba(8, 15, 52, 0.06);
  backdrop-filter: blur(2px);
}

.video-js .vjs-control {
  position: absolute;
}

.video-js .vjs-progress-control {
  bottom: 29px;
  left: 0;
  right: 0;
  height: 4px;
  margin: 0;
  width: 100%;
}
.video-js .vjs-progress-holder {
  position: absolute;
  left: -10px;
  margin: 0;
  right: 0;
  height: 3px;
  width: 100%;
}
.video-js .vjs-play-progress,
.video-js .vjs-load-progress {
  height: 3px;
}

.video-js .vjs-play-progress {
  background-color: rgb(193, 18, 31);
}

.video-js .vjs-load-progress {
  background: rgba(255, 255, 255, 0.2);
}

.video-js .vjs-play-progress:before {
  font-size: 12px;
  top: -5px;
  text-shadow: 0 0 1px black;
}

.video-js .vjs-seek-button {
  position: absolute;
}

.video-js .vjs-seek-button.skip-back {
  left: 32px;
}

.video-js .vjs-seek-button.skip-forward {
  left: 64px;
}

.video-js .vjs-time-control {
  display: block;
  position: absolute;
}

.video-js .vjs-current-time-display {
  position: absolute;
  display: block;
  left: 128px;
  text-align: right;
}

.video-js .vjs-time-divider {
  position: absolute;
  display: block;
  left: 164px;
  text-align: center;
}

.video-js .vjs-duration-display {
  position: absolute;
  display: block;
  left: 192px;
}

.vjs-current-time-display,
.vjs-time-divider span,
.vjs-duration-display {
  font-weight: 400;
  font-size: 12px;
}

.video-js .vjs-remaining-time {
  display: none;
}

.video-js .vjs-volume-panel {
  position: absolute;
  bottom: 0;
  right: 64px;
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
  transition: visibility 2s, opacity 2s, height 2s 2s, width 2s 2s;
}

.video-js .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-vertical,
.video-js .vjs-volume-panel:active .vjs-volume-control.vjs-volume-vertical,
.video-js .vjs-volume-panel:focus .vjs-volume-control.vjs-volume-vertical,
.video-js .vjs-volume-panel .vjs-volume-control:active.vjs-volume-vertical,
.video-js
  .vjs-volume-panel.vjs-hover
  .vjs-mute-control
  ~ .vjs-volume-control.vjs-volume-vertical,
.video-js
  .vjs-volume-panel
  .vjs-volume-control.vjs-slider-active.vjs-volume-vertical {
  position: absolute;
  left: 5px;
  bottom: 30px;
  transition: none;
}

.video-js div.vjs-subtitles-button {
  position: absolute;
  bottom: 0;
  right: 96px;
}

.video-js .vjs-playback-rate {
  position: absolute;
  bottom: 0;
  right: 32px;
}
.video-js .vjs-fullscreen-control {
  position: absolute;
  bottom: 0;
  right: 0;
}

.video-js .vjs-big-play-button {
  border: 0;
  border-radius: 50%;
  height: 64px;
  width: 64px;
  background: rgba(74, 74, 74, 0.5);
  backdrop-filter: blur(2px);
}

.vjs-big-play-centered .vjs-big-play-button {
  margin-top: -35px;
  margin-left: -35px;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: '\f101';
  font-size: 40px;
  height: 23.33px;
  width: 18.33px;
  left: 20.33%;
  right: 33.83%;
  top: 16%;
  bottom: 24%;
}
